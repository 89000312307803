import React from "react";
import { CssBaseline, 
        makeStyles, 
        createStyles,  } from "@material-ui/core";
import favicon from "../images/static/favicon.ico";
import { Helmet } from "react-helmet";

const muiStyles = makeStyles(() => {
  return createStyles({
    mainWrapper: {
      backgroundColor: '#afc7d1',
    },
    copyright: {
      lineHeight: '1.375rem',
      fontSize: '0.8669rem',
    }
  });
});

const Layout = ({ children }) => {
  const classes = muiStyles();

  return (
   <React.Fragment>
      <CssBaseline />
      <Helmet>
        <link rel="icon" href={favicon} />
      </Helmet>
      <main className={classes.mainWrapper}>
        {children}
      </main>
   </React.Fragment>
  )
};

export default Layout;