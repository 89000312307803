import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { zippo } from "../utils/utilityFunctions";
import Layout from "../components/Layout";
import {  Container, 
          Grid, 
          makeStyles, 
          createStyles, 
          Typography,
          option,
          FormHelperText,
          FormControl,
          Select,
          TextField,
          Button,
          Modal,
          Link,
        }  from "@material-ui/core/";
import CancelIcon from '@material-ui/icons/Cancel';
import Footer from '../components/footer';

import ScriptEditorImage from "../images/notes.svg";
import PhotoGalleryImage from "../images/images.svg";
import LoginImage from "../images/key.svg";

const muiStyles = makeStyles((theme) => {

  const inputTheme = {
    backgroundColor: "#ffffff",
    color: "#000000",
    borderRadius: "4px",
    border: "1px solid #e6e6e6",
  };

  const headingTheme = {
    color: "#000000",
    fontFamily: 'Karla, Helvetica, Arial, sans-serif',
    fontWeight: '700',
  }

  return createStyles({
    imageWrapper: {
      display: 'grid',
    },
    heroImage: {
      gridArea: '1/1',
      maxHeight: '550px',
    },
    heroOverlay: {
      opacity: '0.41',
      backgroundColor: '#FFFFFF',
      gridArea: '1/1',
    },
    heroContentWrapper: {
      gridArea: "1/1",
      position: "relative",
      placeItems: "center",
      display: "grid",
    },
    heroContent: {
      height: "100%",
    },
    heroHeadline:{
      ...headingTheme,
      textAlign: 'center',
      lineHeight: '2.875rem',
      fontSize: '2.3529rem',
    },
    heroSubHeader: {
      color: "#000000",
      textAlign: 'center',
      lineHeight: '1.1875rem',
      fontSize: '0.9907rem',
    },
    getQuoteForm: {
      textAlign: 'center',
    },
    getQuoteFormSelect: {
      ...inputTheme,
      '&& option, && optgroup': {
        ...inputTheme,
      },
      '&& .MuiSelect-icon': {
        color: '#000000',
      }
    },
    getQuoteZipcode:{
      ...inputTheme,
    },
    getQuoteFormSubmit:{
      padding: '15px 32px',
      backgroundColor: 'rgb(92, 190, 92)',
      borderRadius: 0,
      fontWeight: 700,
      '&&:hover': {
        backgroundColor: '#30c230',
      }
    },
    inputError: {
      color: '#FFFFFF',
    },
    tipsContainer: {
      textAlign: 'center',
      padding: '30px 45px',
    },
    tipsHeading:{
      ...headingTheme,
      lineHeight: '2.375rem',
      fontSize: '1.4861rem',
    },
    p: {
      lineHeight: '1.625rem',
      fontSize: '0.9907rem',
    },
    articleImage: {
      gridArea: '1/1',
      height: '175px',
    },
    articleTitle: {
      lineHeight: '1.8125rem',
      fontSize: '1.1146rem',
      paddingTop: '20px',
      paddingBottom: '20px',
      textAlign: 'left',
      fontWeight: '600',
    },
    articleExcerpt: {
      lineHeight: '1.625rem',
      fontSize: '0.9907rem',
      textAlign: 'left',
    },
    articleReadMore: {
      lineHeight: '1.625rem',
      fontSize: '0.9907rem',
      color: '#1c6bbd',
      textAlign: 'justify',
      cursor: 'pointer',
    },
    stepsHeading:{
      ...headingTheme,
      lineHeight: '2.875rem',
      // fontSize: '2.3529rem',
    },
    stepTitle:{
      ...headingTheme,
      lineHeight: '1.5625rem',
      fontSize: '1.1146rem',
      marginTop: '20px',
    },
    stepText:{
      lineHeight: '1.25rem',
      fontSize: '0.8669rem',
    },
    stepCircle: {
      border: 0,
      background: 'rgb(240,243,245)',
      borderRadius: '50%',
      width: '150px',
      height: '150px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: 'auto',
    },
    stepIcon: {
      height: '3.8125rem',
      width: '3.8125rem',
    },
    modal: {
      overflowY: 'scroll',
      padding:"50px",
      backgroundColor: 'rgba(0,0,0,0.65)',
      [theme.breakpoints.down('sm')]: {
        padding:"0px",
      },
    },
    modalWrapper: {
      padding:"50px",
      outline: 'none',
      [theme.breakpoints.down('sm')]: {
        padding:"20px",
      },
    },
    modalCloseIcon: {
      position: 'absolute',
      top: '-20px',
      right: '-5px',
      fontSize: '2.5em',
      border: '1px solid #FFFFFF',
      borderRadius: '50%',
      backgroundColor: '#FFFFFF',
    },
    modalContent: {
      backgroundColor: '#ffffff',
      border: '0px solid #000',
      padding: '20px',
      textAlign: 'left',
      lineHeight: '1.8125rem',
      fontSize: '1.1146rem',
      color: '#37465A',
      borderRadius: '10px',
      position: 'relative',
      outline: 'none',
    },
    footerGetQuote: {
      paddingTop: '50px',
      paddingBottom: '50px',
    },
    footerGetQuoteInputError: {
      color: 'red',
    }
  });
});

// yup validation schema -- overkill but easy to use
const schema = yup.object().shape({
  zipcode: yup
    .string()
    .min(5, "Zipcode must be 5 characters")
    .max(5, "Zipcode must be 5 characters")
    .matches(/(^\d{5}$)/, "Please enter a valid zipcode")
    .required("Zip Code is required"),
  vertical: yup.string().required("This field is required"),
});

const GetQuoteForm = ({className, inputErrorClassName, inputLayout = "default"}) => {
  const [validZip, setValidZip] = useState();
  const [zippoResponse, setZippoResponse] = useState();

  const classes = muiStyles();

  // react-hook-form useForm Hook
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    if (validZip) {
      const { city, state } = zippoResponse;

      // performs the redirect via Gatsby's navigate method.  We pass the state to be stored in sessionStorage on the TYP
      navigate("/thankyou/", { state: { ...data, city, state } });
    }
  };

  const handleChange = (e) => {
    const { value } = e.target;
    const noString = value.replace(/\D/g, "");

    // prevents strings in this input
    setValue("zipcode", noString, {
      shouldValidate: true,
      shouldDirty: true,
    });    

    if (value && value.length === 5) {

      // calls the zippo API to get the city/state which gets stored in sessionStorage
      zippo(value).then((res) => {
        if (res) {
          clearErrors("zipcode");

          // zipcode input is not a controlled input so we ref the DOM to remove the class. 
          document.getElementById("zipcode").classList.remove("border-error");
          setZippoResponse(res);
          setValidZip(true);
        } else {
          setValidZip(false);
        }
      });
    }
  };

  useEffect(() => {
    // can't be a falsy check here need to check for explicit false value 
    if (validZip === false) {
      setError("zipcode", {
        type: "manual",
        message: "Please enter a valid zipcode.",
      });
    }
  }, [validZip, setError]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={className}>
      <Grid container justify={inputLayout === "inline" ? "center" : "left"}>
        <Grid container item xs={12} sm={inputLayout === "inline" ? 4 : 7} justify="flex-end">
          <Grid item xs={12} sm={inputLayout === "inline" ? 11 : 9} >
            <FormControl variant="outlined" fullWidth>
              <Select
                id="quoteType"
                displayEmpty
                defaultValue=""
                native
                className={classes.getQuoteFormSelect}
                {...register("vertical")}
              >
                <option value="" disabled>
                  What are you looking for?
                </option>
                <option value="refinance">Refinance</option>
                <option value="purchase">Home Mortgage</option>
                <option value="dpa">Down Payment Assistance (DPA)</option>
                <option value="rto">Rent to Own</option>
                <optgroup label="Insurance">
                  <option value="auto">Auto Insurance</option>
                  <option value="home">Home Insurance</option>
                  <option value="life">Life Insurance</option>
                  <option value="medsup">Medicare Support</option>
                </optgroup>
                <option value="solar">Solar</option>
                <option value="windows">Windows</option>
                <option value="roofing">Roofing</option>
              </Select>
              <FormHelperText className={inputErrorClassName || classes.inputError}>{errors.vertical?.message || " "}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={inputLayout === "inline" ? 4 : 7} justify="flex-end">
          <Grid item xs={12} sm={inputLayout === "inline" ? 11 : 9} >
            <FormControl variant="outlined" fullWidth>
              <TextField
                id="zipcode"
                placeholder="Zip Code"
                name="zipcode"
                variant="outlined"
                {...register("zipcode")}
                onChange={handleChange}
                InputProps={{
                  className: classes.getQuoteZipcode,
                }}
                autoComplete="off"
                value={getValues("zipcode")}
              />
              <FormHelperText className={inputErrorClassName ||  classes.inputError}>{errors.zipcode?.message || " "}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={inputLayout === "inline" ? 4 : 5} justify={inputLayout === "inline" ? "flex-end" : "flex-start"}>
          <Grid item xs={12} sm={inputLayout === "inline" ? 11 : 9} md={inputLayout === "inline" ? 11 : 10} >
            <Button variant="contained" color="primary" type="submit" className={classes.getQuoteFormSubmit} fullWidth={inputLayout === "inline" ? true : false}>
              Get Quote
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

/* const Hero = () => {
  const classes = muiStyles();
  return (
    <div className={classes.imageWrapper}>
      <Container className={classes.heroContentWrapper} maxWidth="md">
        <br/><br/><br/>
        <GetQuoteForm className={classes.getQuoteForm} inputLayout/>
        <br/><br/>
      </Container>
    </div>
  );
}; */

const Tips = () => {
  const classes = muiStyles();

  return (
    <div style={{backgroundColor: '#fbfbfb',}}>
      <Container className={classes.tipsContainer} maxWidth="md">
        <Typography variant="h4" component="h4" className={classes.stepsHeading}>
          We want to help you save money wherever possible. 
        </Typography>
        <p className={classes.p}>
          Everyone has either a car insurance, renters insurance, a mortgage or is interested in buying a home. We have the resources for you to get the best deals and information. There are so many things that people are not aware of that could save them money, such as installing solar panels (did you know that there are programs where you have no or very low out of pocket expenses to get them installed?) or personal loan options with low interest rates. 
        </p>
        <p className={classes.p}>
          We are here to show you options and give you the best resources. 
        </p>
      </Container>
    </div>
  )
};

const Blogs = ( { setModalProps }) => {
  const classes = muiStyles();

  return (
    <div style={{backgroundColor: '#ffffff',}}>
      <Container className={classes.tipsContainer} maxWidth="md">
        <Typography variant="h4" component="h4" className={classes.stepsHeading}>
          Here are some informative articles for you.
        </Typography>
        <Grid container  spacing={6}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" component="h6" className={classes.articleTitle}>
              Understanding the Different Types of Car Insurance Policies
            </Typography>
            <p className={classes.articleExcerpt}>
              Buying car insurance can get confusing. You have so many options and each comes with an additional premium. The law requires liability insurance, but what do you need beyond that? It’s not as confusing as you think.
            </p>
            <p className={classes.articleReadMore}>
              <Link href="#" onClick={() => setModalProps({
                isOpen: true,
                title: 'Understanding the Different Types of Car Insurance Policies',
                description: 'Understanding the Different Types of Car Insurance Policies',
                content: (
                  <React.Fragment>
                    <Typography variant="h3" component="h3">Understanding the Different Types of Car Insurance Policies</Typography>
                    <p>
                      Buying car insurance can get confusing. You have so many options and each comes with an additional premium. The law requires liability insurance, but what do you need beyond that? It’s not as confusing as you think.
                      <br/>
                      <a href="//autogo2.insurancespecialists.com/?o=2684&a=256&c=13330&cpid=25808&p=r&s1=content#first-frame" rel="nofollow">Get today’s insurance rates.</a>
                      <br/>
                      Below we help you understand your options and the pros and cons of each.
                    </p>
                    <p>
                      <strong>Liability Insurance – It’s Required</strong><br/>
                      Let’s start with the required insurance. You need liability insurance if you want to be on the road. By you driving, you put others at risk. No, that doesn’t mean you’re a bad driver. But, anything could happen. If you cause harm to someone else or personal property, you need to pay for it. Without insurance, you could be looking at thousands of dollars. The government can’t risk that, which is why you need liability coverage.
                    </p>
                    <p>
                      <strong>Liability insurance has two coverage types:</strong><br/>
                      <ul>
                        <li>Bodily injury – This covers the cost of injuries caused to another person – not you or your passengers</li>
                        <li>Property damage – This covers the cost of repairing the other cars involved as well as any property damaged (storefronts, homes, etc.)</li>
                      </ul>
                    </p>
                    <p>
                      <strong>Comprehensive Insurance</strong><br/>
                      Your state may not require comprehensive insurance, but if you finance your car, you may need it. Banks use your car as collateral. Without comprehensive insurance, paying for damages could get costly, even unaffordable.
                    </p>
                    <p>
                      Comprehensive coverage protects your car from non-collision type accidents. Things like hail damage, theft, vandalism, and fire fall under this category. Anything that doesn’t involve crashing with another car may fall under this category.
                    </p>
                    <p>
                      Comprehensive insurance typically has a deductible. You must cover this amount before the insurance covers the damages. Choose your deductible wisely. The higher the deductible you choose, the lower the premiums the insurance company can offer. But, accepting an unaffordable deductible won’t help you in the face of an accident.
                    </p>
                    <p>
                      <strong>Collision Insurance</strong><br/>
                      As the name suggests, collision insurance protects you during a crash. If you are at fault, this covers the damage to your vehicle. The coverage helps pay for repairs and/or replacement of your car, should the insurance company total it.
                    </p>
                    <p>
                      This coverage only covers your car. It goes into effect with multiple car crashes as well as single-car crashes (i.e. crashing into a fence, light pole, etc.).
                    </p>
                    <p>
                      Like comprehensive insurance, you’ll have a deductible. You must meet that amount before the insurance company will cover the damages. Keep your deductible at a reasonable amount.
                    </p>
                    <p>
                      <a href="//autogo2.insurancespecialists.com/?o=2684&a=256&c=13330&cpid=25808&p=r&s1=content#first-frame" rel="nofollow">Shop and compare insurance quotes.</a>
                    </p>
                    <p>
                      <strong>Medical Payments Insurance</strong><br/>
                      Liability insurance doesn’t cover injuries you or your passengers experience. Medical payments coverage does, though. This insurance is optional. If you don’t take it, you’ll pay the costs of medical expenses out of pocket.
                    </p>
                    <p>
                      It only comes into effect if you are at fault for the accident. If another driver causes the accident, their liability insurance covers your medical bills. Medical payments coverage includes the cost of doctors, hospitals, x-rays, surgery, and physical therapy, as a few examples.
                    </p>
                    <p>
                      Carefully consider the limits of medical payments coverage. Ask your agent about the average cost of a hospital visit or recovery from standard car accident injuries. That gives you a starting point. Any costs that exceed your coverage become your responsibility.
                    </p>
                    <p>
                      <strong>Personal Injury Protection</strong><br/>
                      Personal injury protection takes medical payments coverage one step further. Like medical payments insurance, it covers the medical costs incurred after a car accident. It also covers lost wages and childcare expenses that result from the accident.
                    </p>
                    <p>
                      This insurance covers you no matter who is at fault. Not all states offer this insurance, so check with your provider to see if it’s an option. 
                    </p>
                    <p>
                      <strong>Towing Insurance</strong><br/>
                      If you have comprehensive insurance, you may tack on a towing rider. This insurance covers the cost (up to a limit) to tow your car. Some towing insurance riders include labor insurance as well. This coverage helps reimburse you for the cost of labor to repair the vehicle as a result of a comprehensive insurance loss.
                    </p>
                    <p>
                      <strong>Gap Insurance</strong><br/>
                      Cars depreciate quickly, sometimes faster than you can pay the loan balance down. If you total your car early in ownership, you may owe more than the car’s worth. Gap insurance covers the ‘gap.’ This helps reduce your financial responsibility as you pick up the pieces and get a new car.
                    </p>
                    <p>
                      <strong>Rental Reimbursement Coverage</strong><br/>
                      You rely on your car. When you are in an accident, you can feel lost. How do you get around? Renting a car gets expensive, especially long-term. Rental reimbursement coverage helps reduce your out of pocket costs if you must rent. Make sure you know your daily and overall limits when using your coverage, though.
                    </p>
                    <p>
                      Car insurance seems like a nuisance, but you’ll be grateful for it if you have a claim. You pay the premiums year-round, but when you have to file a claim, it all becomes worth it. Choose your insurance wisely. Choose the right premium and deductible that suits your financial needs while providing the necessary protection.
                    </p>
                    <p>
                      <a href="//autogo2.insurancespecialists.com/?o=2684&a=256&c=13330&cpid=25808&p=r&s1=content#first-frame" rel="nofollow">Get the right insurance coverage.</a>
                    </p>
                  </React.Fragment>
                ),
              })}>READ MORE</Link>
            </p>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h4" component="h4" className={classes.articleTitle}>
              Insurance Tips for First Time Homeowners
            </Typography>
            <p className={classes.articleExcerpt}>
              Buying your first home is exciting, but it also comes with a lot of responsibility. If you take out a mortgage to buy the home, the mortgage company will require that you carry homeowner’s insurance. Before you just call any ...
            </p>
            <p className={classes.articleReadMore}>
              <Link href="#" onClick={() => setModalProps({
                isOpen: true,
                title: 'Insurance Tips for First Time Homeowners',
                description: 'Insurance Tips for First Time Homeowners',
                content: (
                  <React.Fragment>
                    <Typography variant="h3" component="h3">Insurance Tips for First Time Homeowners</Typography>
                    <p>
                      Buying your first home is exciting, but it also comes with a lot of responsibility. If you take out a mortgage to buy the home, the mortgage company will require that you carry homeowner’s insurance. Before you just call any insurance company and take what they have to offer, learn the important tips you should use to help you make the most of your policy.
                    </p>
                    <p>
                      <a href="//home.insurancespecialists.com/#first-frame" rel="nofollow">Get today’s insurance rates.</a>
                    </p>
                    <p>
                      <strong>Insurance Companies Look at Your Past</strong><br/>
                      Even though you’ve never owned a home before, insurance companies are going to look at your insurance history. Maybe you had renter’s insurance or car insurance. Did you make a lot of claims on those policies? If so, you may be considered a high-risk for claims. This will prevent you from getting the claim-free discount. It could also prevent certain insurance companies from insuring your home at all.
                    </p>
                    <p>
                      <strong>You Need to Know if the Home is in a Flood Zone</strong><br/>
                      Homeowner’s insurance doesn’t cover flood damages. Your lender will likely require a flood certification on the property to determine if you are at risk for flooding. If the home is located in a flood zone, you will be required to purchase flood insurance, which is separate from homeowner’s insurance. You’ll pay a separate premium and possibly even have a separate insurance company that will cover flood damages.
                    </p>
                    <p>
                      If the home is in a flood zone, make sure you know exactly what your insurance company will cover. If there are any damages that they can tie into the flood, chances are your homeowner’s insurance won’t cover it. Knowing this ahead of time can help prevent an unpleasant surprise should you have to file a claim.
                    </p>
                    <p>
                      <strong>Don’t Take the Cheapest Offer</strong><br/>
                      It might seem like it makes sense to buy the cheapest insurance policy available. After all, who doesn’t like to save money each month, right? But this can hurt you in the end. Rather than focusing on just the premiums, look at what the coverage offers.
                    </p>
                    <p>
                      Do you have enough dwelling protection? Do you have replacement cost coverage? Is there a separate rider for your personal belongings? Do you need additional riders for luxurious personal belongings, such as jewelry? Do you have enough coverage if someone were to get hurt on your property?
                    </p>
                    <p>
                      These are the factors you should focus on alongside the premiums. It makes sense to choose the lower premiums between the policies that offer the right coverage. Just don’t make the mistake of focusing on premiums alone.
                    </p>
                    <p>
                      <a href="//home.insurancespecialists.com/#first-frame" rel="nofollow">Shop and compare insurance quotes.</a>
                    </p>
                    <p>
                      <strong>Your Credit Score May Matter</strong><br/>
                      You know your credit score mattered when you applied for a home loan, but it may also affect your homeowner’s insurance rates too. Some insurance providers pull your credit. They want to know what type of financial risk you pose.
                    </p>
                    <p>
                      Do you pay your bills on time? Do you use your available credit responsibly? These are things the insurance company will look at before they determine your premium. The lower your credit score is or the more ‘bad’ credit you have on your report, the higher the premium an insurance company may charge you.
                    </p>
                    <p>
                      <strong>Bundle Your Insurance</strong><br/>
                      One of the best things you can do is use the same insurance company for multiple policies. For example, if you have a car, use the same agent for your car and homeowner’s insurance. These two policies bundled together often offer the highest discount. Check with your insurance agent, though, because some companies offer bundling discounts for other policies as well.
                    </p>
                    <p>
                      The key is to shop around, make sure you get proper coverage, and that you get premiums that you can afford. Also, pay attention to the deductibles. If you end up making a claim, you want a deductible that you can afford, not one that will make you feel financially stressed. Once you put all of the pieces together, you can get the right insurance policy for your first home.
                    </p>
                    <p>
                      <a href="//home.insurancespecialists.com/#first-frame" rel="nofollow">Get the right insurance coverage.</a>
                    </p>
                  </React.Fragment>
                  )
                  })}
                >READ MORE</Link>
            </p>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" component="h6" className={classes.articleTitle}>
              10 Lesser Known Home Insurance Discounts
            </Typography>
            <p className={classes.articleExcerpt}>
              Everyone knows that auto-paying and bundling insurance can save money, but what other home insurance discounts are available?
            </p>
            <p className={classes.articleReadMore}>
              <Link onClick={() => setModalProps({
                isOpen: true,
                title: '10 Lesser Known Home Insurance Discounts',
                description: '10 Lesser Known Home Insurance Discounts',
                content: (
                  <React.Fragment>
                    <Typography variant="h3" component="h3">10 Lesser Known Home Insurance Discounts</Typography>
                    <p>
                      Everyone knows that auto-paying and bundling insurance can save money, but what other home insurance discounts are available?
                    </p>
                    <p>
                      <a href="//home.insurancespecialists.com/#first-frame" rel="nofollow">Get today’s insurance rates.</a>
                    </p>
                    <p>
                      <strong>Paid-in-Full Discount</strong><br/>
                      If you’re paying for your insurance monthly, you’re paying too much. If you pay your premiums in full, you can save yourself a total of 5 percent after taxes. Switching is easy. Most insurance companies let you make the switch online, or you can phone your agent to switch.
                    </p>
                    <p>
                      <strong>Age-Related Discount</strong><br/>
                      You don’t have to be retirement age to take advantage of <a href="//home.insurancespecialists.com/#first-frame" rel="nofollow">age-related discounts</a>. Many insurance companies offer discounts to homeowners aged 50 and older. If you purchased your home while you were in your early twenties, you may be eligible for a better rate after you turn 25.
                    </p>
                    <p>
                      <strong>Good Student Discount</strong><br/>
                      Got a smart kid? Parents are eligible to receive discounts for their children who get good grades (this includes students attending college). The discount varies by insurance company, and most companies ask that kids maintain at least a B average.
                    </p>
                    <p>
                      <strong>Renovation Discount</strong><br/>
                      Planning an update? If so, let your insurance company know. Your insurance company wants you to take good care of your home, and will reward you when you do.
                    </p>
                    <p>
                      <strong>Homeowners Association Membership Discount</strong><br/>
                      Belonging to a <a href="//home.insurancespecialists.com/#first-frame" rel="nofollow">homeowner’s association</a> is advantageous because it can reduce your insurance costs. Insurance companies know that homeowner’s associations have rigid rules that require regular home maintenance and care. The result is fewer claims, which is why insurance companies offer discounts for your membership.
                    </p>
                    <p>
                      <strong>Multi-Policy Discount (More than Just Home and Car)</strong><br/>
                      The more policies you bundle together, the greater the savings, and there’s more to bundle than you think: boats, RVs, off-road vehicles, health insurance, vacation homes etc.
                    </p>
                    <p>
                      <a href="//home.insurancespecialists.com/#first-frame" rel="nofollow">Shop and compare insurance quotes.</a>
                    </p>
                    <p>
                      <strong>No Claims Discount</strong><br/>
                      If you’ve never made a claim against your insurance, you’re entitled to additional savings. <a href="https://www.realtor.com/advice/buy/what-homeowners-insurance-discounts-are-you-missing/" rel="nofollow">Realtor.com</a> reports: “If you haven’t filed a home insurance claim in the last 10 years you may want to ask about a discount. Homeowners who haven’t made a claim can often get as much as a 20 percent reduction.”
                    </p>
                    <p>
                      <strong>High Credit Score Discount</strong><br/>
                      If you have a low credit score, you may be overpaying for your homeowner’s insurance. The customers with the highest credit scores often receive the best rates, while those with low credit scores are subjected to higher rates. If your credit is less than perfect, it may be time to improve it. Then, contact your agent, and ask him to provide you with a better insurance rate – one that acknowledges your high credit score.
                    </p>
                    <p>
                      <strong>Buy Early and Save Discount</strong><br/>
                      Don’t wait until your policy ends to get an insurance quote. Many insurance companies provide hefty discounts if you switch to a new policy before the first policy expires. If you pay your insurance in full, a portion of those funds (whatever you didn’t use) will be refunded to you.
                    </p>
                    <p>
                      <strong>Home Safety and Security Discounts</strong><br/>
                      Everyone knows a security system can net them some additional savings, but did you also know smoke alarms, strong roofs, and other safety features can save you money? Your home’s safety features make it low-risk, which most insurance companies reward.
                    </p>
                    <p>
                      Don’t gamble with your financial future. Contact your insurance company, and ask them how you can apply these lesser-known discounts. If they refuse to provide you with additional savings, it may be time to switch to an insurance company that will.
                    </p>
                    <p>
                      <a href="//home.insurancespecialists.com/#first-frame" rel="nofollow">Get the right insurance coverage.</a>
                    </p>
                  </React.Fragment>
                  )
                  })}
                >READ MORE</Link>
            </p>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
};

const Steps = () => {
  const classes = muiStyles();

  return (
    <div style={{backgroundColor: '#ffffff',}}>
      <Container className={classes.tipsContainer}>
        <Typography variant="h4" component="h4" className={classes.stepsHeading}>
          We Bring The Pros To You In <br/>
          3 Simple Steps
        </Typography>
        <Grid container spacing={10} justify="center" className={classes.tipsContainer}>
          <Grid item xs={12} sm={4} md={3}>
            <div className={classes.stepCircle}>
              <img src={ScriptEditorImage} alt="Fill us in." className={classes.stepIcon} />
            </div>
            <Typography variant="h3" component="h3" className={classes.stepTitle}>
              Fill us in.
            </Typography>
            <p className={classes.stepText}>
              Share some basic info to get customized service.
            </p>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <div className={classes.stepCircle}>
              <img src={PhotoGalleryImage} alt="Take your pick." className={classes.stepIcon} />
            </div>
            <Typography variant="h3" component="h3" className={classes.stepTitle}>
              Take your pick.
            </Typography>
            <p className={classes.stepText}>
              Compare best estimates from top companies.
            </p>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <div className={classes.stepCircle}>
              <img src={LoginImage} alt="Ink your deal." className={classes.stepIcon} />
            </div>
            <Typography variant="h3" component="h3" className={classes.stepTitle}>
              Ink your deal.
            </Typography>
            <p className={classes.stepText}>
              Connect with a local agent to get a quote or apply online.
            </p>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
};

const FooterGetQuote = () =>  {
  const classes = muiStyles();

  return (
    <Grid container justify="center" alignItems="center" className={classes.footerGetQuote}>
      <Grid item xs={10} lg={7} xl={5}>
        <GetQuoteForm className={classes.getQuoteForm} inputErrorClassName={classes.footerGetQuoteInputError} inputLayout="inline" />
      </Grid>
    </Grid>
  );
};

const IndexPage = () => {
  const classes = muiStyles();

  const [ modalProps, setModalProps ] = useState({ isOpen: false, title: '', description: '', content: ''});

  const closeModal = () =>{
    setModalProps({ isOpen: false, title: '', description: '', content: ''});
  }

  return (
    <Layout className={classes.pageBG} >
      <FooterGetQuote />
      <Tips />
      <Blogs setModalProps={setModalProps} />
      <Steps />
      <FooterGetQuote />
      <Footer />
      <Modal
        open={modalProps.isOpen}
        onClose={closeModal}
        className={classes.modal}
        id="blog-modal"
        disableBackdropClick
      >
        
        <div className={classes.modalWrapper}>
          <Container className={classes.modalContent}>
            <CancelIcon className={classes.modalCloseIcon} onClick={closeModal}/>
              {modalProps.content}
          </Container>
        </div>
      </Modal>
    </Layout>
  )
};

export default IndexPage;
